//#TODO: Change backend:
// 1. "All Applications -> All Apps"
// 2. Host /links folder as a return of API (rather housing local to app)

import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
// import { styled } from "@mui/styles";
import { useDispatch } from "react-redux";
import { registerError } from "../../store/error-slice";
import {
  Card,
  CardProps,
  CardActionArea,
  Typography,
  Box,
  Tooltip,
  Skeleton,
  TextField,
} from "@mui/material";

import {
  Excel,
  Teams,
  SharePoint,
  Word,
  OneDrive,
  OneNote,
  Forms,
  Bookings,
  Lists,
  Stream,
  Planner,
  Sway,
  PowerPoint,
  ToDo,
  Whiteboard,
  Outlook,
  Office,
  Canva,
  Flip
} from "../../img/Icons";

import {
  People as PeopleIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { getFromSS, setToSS } from "../../util/storage";
import { useTranslation } from "react-i18next";
import { profileService } from "../../util/Services";
import { O365App, isServerErrorResponse } from "../../adapters/ApiSchema";
import { withEventTargetValue } from "../../adapters/Eventing";
import { windowOpen } from "../../adapters/windowUtils";

const PREFIX = 'MyApps';

const classes = {
  wrap: `${PREFIX}-wrap`,
  search: `${PREFIX}-search`,
  item: `${PREFIX}-item`,
  action: `${PREFIX}-action`,
  label: `${PREFIX}-label`
};

const Root = styled(Card)((
  {
    theme
  }
) => (
  {
    [`& .${classes.wrap}`]: {
      display: "grid",
      gridGap: theme.spacing(10),
      gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
      margin: "40px 5vw",
      [theme.breakpoints.down('lg')]: {
        margin: "40px 0 0 0",
        gridGap: theme.spacing(4),
        gridTemplateColumns: "1fr",
        paddingBottom: theme.spacing(4),
      },
    },

    [`& .${classes.search}`]: {
      height: 48, // Make sure you're staying consistent with the search bar location of other pages (e.e.g MyLinks)
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    [`& .${classes.item}`]: {
      width: APP_DIM,
      justifySelf: "center",
      [theme.breakpoints.down('lg')]: {
        width: "70vw",
      },
    },

    [`& .${classes.action}`]: {
      display: "flex",
      flexDirection: "column",
      height: APP_DIM,
      justifyContent: "start",
    },

    [`& .${classes.label}`]: {
      display: "block",
      color: theme.palette.primary.contrastText,
      margin: "-12px",
    }
  }));

const APP_DIM = 125;

export const SS_PREF = "MY_APPS";
const SS_APPS = `${SS_PREF}_APPS`;
const MyApps = () => {

  const dispatch = useDispatch();
  const [apps, setApps] = useState<O365App[]>([]);
  const [appIcons, setAppIcons] = useState<any>({});
  // const [original, setOriginal] = useState<any[]>([]);
  const [original, setOriginal] = useState<O365App[]>([]);
  const [hasApps, setHasApps] = useState<boolean>(true);
  const [filter, setFilter] = useState<string>("");
  const [loaded, setLoaded] = useState<boolean>(false); //seState<boolean>(getFromSS(SS_APPS) ? true : false);
  const { t } = useTranslation();

  const dummy = new Array(18).fill(0);

  const onFilter = (text: string) => {
    setFilter(text);
    setApps(
      original.filter(
        (elem: any) => elem.Title.toLowerCase().indexOf(text.toLowerCase()) > -1
      )
    );
  };

  // Reset scroll
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // attempts to use session storage to get my apps list instead of an api call
    let _apps = getFromSS<O365App[] | undefined>(SS_APPS);

    if (!_apps) {
      profileService.get365Apps()
        .then(res => {
          if (!isServerErrorResponse(res)) {
            setAll(res.Apps);
            setToSS(SS_APPS, res.Apps);
          } else {
            registerError(dispatch, res, "My Apps", false);
          }
        })
        .catch((e) => {
          registerError(dispatch, e, "My Apps", true);
        });
    } else {
      setAll(_apps);
    }
  }, [dispatch]);

  const setAll = (_apps: O365App[]) => {
    setOriginal(_apps);
    setApps(_apps);
    setHasApps(_apps.length !== 0);

    let _icons: any = {};
    _apps.map((app: O365App) => {
      return (_icons[app.Title] = React.cloneElement(getAppIcon(app).icon, {
        height: 100,
        onLoad: () => setLoaded(true),
      }));
    });
    setAppIcons(_icons);
  };
  return (
    <Root data-file="MyApps">

      {hasApps ? (
        <>
          <Box
            className={classes.search}
            sx={{ display: "flex", alignItems: "flex-end" }}
          >
            {original.length === 0 ? (
              <Skeleton width={350} height={32} animation="wave" />
            ) : (
              <>
                <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                  sx={{ width: 350 }}
                  id="my-apps-input-with-sx"
                  onChange={withEventTargetValue(onFilter)}
                  value={filter}
                  label={t("MyApps.Filter")}
                  variant="standard"
                />
              </>
            )}
          </Box>

          <Box className={classes.wrap}>
            {original.length === 0
              ? dummy.map((_, index) => (
                <Skeleton
                  key={index}
                  animation="wave"
                  variant="rectangular"
                  height="125px"
                  className={classes.item}
                />
              ))
              : apps.map((app) => (
                <Tooltip
                  describeChild
                  key={app.Title}
                  title={`Open ${app.Title}`}
                  arrow
                >
                  <Box className={classes.item}>
                    <Link
                      loaded={loaded}
                      backgroundColor={getAppIcon(app).color}
                      title={app.Title}
                    >
                      <CardActionArea
                        onClick={() => windowOpen(app.Url, "_blank")}
                        className={classes.action}
                      >
                        {/* {React.cloneElement(getIcon(app.Title).icon, {height: 100, onLoad: () => setLoaded(true)})} */}
                        {appIcons[app.Title]}
                        <Typography
                          variant="body1"
                          align="center"
                          className={classes.label}
                        >
                          {app.Title === "All Applications"
                            ? "All Apps"
                            : app.Title}
                        </Typography>
                      </CardActionArea>
                    </Link>
                  </Box>
                </Tooltip>
              ))}
          </Box>
        </>
      ) : (
        <Typography variant="body1" align="center">
          {t("MyApps.Text")}
        </Typography>
      )}
    </Root>
  );
};

const getAppIcon = (app: O365App) => {
  // TODO: Put these icons on backend

  switch (app.Title) {
    case "Excel":
      return { icon: <Excel />, color: "#177b43" };
    case "Teams":
      return { icon: <Teams />, color: "#4b53bc" };
    case "SharePoint":
      return { icon: <SharePoint />, color: "#0a8286" };
    case "Word":
      return { icon: <Word />, color: "#1e5abc" };
    case "OneDrive":
      return { icon: <OneDrive />, color: "#0078d4" };
    case "OneNote":
      return { icon: <OneNote />, color: "#7719aa" };
    case "Outlook":
      return { icon: <Outlook />, color: "#0078d4" };
    case "Forms":
      return { icon: <Forms />, color: "#038387" };
    case "Bookings":
      return { icon: <Bookings />, color: "#008272" };
    case "Lists":
      return {
        icon: <Lists style={{ transform: "scale(0.6)" }} />,
        color: "#974b8b",
      };
    case "Stream":
      return { icon: <Stream />, color: "#bc1948" };
    case "Planner":
      return { icon: <Planner />, color: "#107c41" };
    case "Sway":
      return { icon: <Sway />, color: "#038387" };
    case "PowerPoint":
      return { icon: <PowerPoint />, color: "#c43e1c" };
    case "People":
      return {
        icon: (
          <PeopleIcon
            style={{ fill: "white", transform: "scale(2.5)", height: 100 }}
          />
        ),
        color: "#173683",
      };
    case "To Do":
      return { icon: <ToDo />, color: "#185abd" };
    case "Whiteboard":
      return { icon: <Whiteboard />, color: "#409efd" };
    case "All Applications":
      return { icon: <Office />, color: "#d83b01" };
    case "Canva":
      return { icon: <Canva />, color: "#00c4cc" };
    case "Flip":
      return { icon: <Flip />, color: "black" };

    default:
      return { icon: <img src={app.Icon} />, color: "#d83b01" };
    // return { icon: <Office />, color: "#d83b01" };
  }
};

/************************ HELPER COMPONENTS ********************/

interface LinkProps extends CardProps {
  loaded: boolean;
  backgroundColor: string;
}
const Link = styled((props: LinkProps) => {
  const { loaded, backgroundColor, ...other } = props;
  return <Card variant="outlined" {...other} />;
})(({ theme, loaded, backgroundColor }) => ({
  backgroundColor: backgroundColor,
  height: APP_DIM,
  opacity: !loaded ? 0 : 1,
  transition: theme.transitions.create(["opacity", "filter"], {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.enteringScreen,
  }),
  "&:hover": {
    filter: "brightness(120%)",
  },
}));

export default MyApps;
