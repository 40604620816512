// adapter for window stuff that needs special handling in special cases

// we have a situation where iphone dcps app is doing nothing when links are clicked
export function windowOpen(url: string, target?: string | undefined, features?: string | undefined) {
    // window.open(url, target, features);
    // TODO: log this to SEQ
    const ua = window.navigator.userAgent;
    if (ua.includes("AppleWebKit") && (ua.includes("iPhone") || ua.includes("iPad")) && !ua.includes("Version")) {
        console.warn("Using apple web kit workaround");
        window.location.href = url;
        return;

    }
    try {
        // checking window.open's return value for null failed to test on localhost
        window.open(url, target, features);
    } catch (ex) {
        // TODO: log this to SEQ
        console.error("Failed to call window.open");
        window.location.href = url;
    }
}