import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useIsAuthenticated } from "@azure/msal-react";

import defaultStore from "./util/Globale";
import { profileService, navigationService, dcpsConfig } from "./util/Services";
import { userActions } from "./store/user-slice";
import { MenuState, menuActions } from "./store/menu-slice";
import { tokenActions } from "./store/token-slice";
import { registerError } from "./store/error-slice";
import { initializeIcons } from "@fluentui/react";


import "./style/App.css";

import initFontAwesome from "./util/FontAwesome";

import Token from "./Token";
import { isServerErrorResponse } from "./adapters/ApiSchema";

// Remove comments from Prod
if (defaultStore.isProduction) {
  // console.log = () => { };
  // console.error = () => { };
  // console.debug = () => { };
}

// this initializes fluent ui icons across the app
initializeIcons();

initFontAwesome();

function App() {
  const { instance, accounts, inProgress } = useMsal();

  const dispatch = useDispatch();

  const isAuthenticated = useIsAuthenticated();

  const APIsToConnect = useCallback((res: AuthenticationResult) => {
    if (res.expiresOn) {
      let payload = {
        token: res.accessToken,
        expiration: new Date(res.expiresOn).toLocaleString(),
      };
      dispatch(tokenActions.updateToken(payload));
    }

    profileService.getUserProfileInfo().then(result => {
      if (!isServerErrorResponse(result)) {
        let payload = {
          user: result,
        };
        // console.log('user profile loaded')
        dispatch(userActions.updateUser(payload));
      } else {
        console.warn(result);
        registerError(dispatch, result, "user", true);
      }
    });

    navigationService.getUserMenu().then(result => {
      // console.log(result);
      if (!isServerErrorResponse(result)) {
        // const items = Object.keys(result.data).includes("Items") ? (result.data as UserMenuResponseData)?.Items : undefined;
        // if (result.status === 200 && items && Array.isArray(items)) {
        let payload: MenuState = {
          menu: result.Items,
        };
        dispatch(menuActions.updateMenu(payload));
      } else {
        console.log(result);
        registerError(dispatch, result, "menu", true);
      }
    });
  }, [dispatch]);

  // TODO: the token callout response does not have an expiresOn property
  // this appears it should be an AuthenticationResult
  const updateNewToken = useCallback((res: AuthenticationResult) => {
    if (res.expiresOn) {

      let payload = {
        token: res.accessToken,
        // typescript thinks this shouldn't work, but it does
        expiration: new Date(res.expiresOn).toLocaleString(),
      };
      dispatch(tokenActions.updateToken(payload));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!accounts || accounts.length < 1) return;
    const requestNew = {
      ...dcpsConfig,
      account: accounts[0],
    };

    function RequestAccessToken() {
      //call to get token

      instance
        .acquireTokenSilent(requestNew)
        .then((res) => {
          APIsToConnect(res);
          //console.log("request2-new", res);
          updateNewToken(res);
        })
        .catch((error: any) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(requestNew);
          }
        });
    }

    if (isAuthenticated && inProgress === "none" && accounts.length !== 0) {
      RequestAccessToken();
    }
  }, [APIsToConnect, accounts, accounts.length, inProgress, instance, isAuthenticated, updateNewToken]);


  return (
    <React.Fragment>
      <Token />
    </React.Fragment>
  );
}

export default App;
