// page purpose: expose important information that is hidden in some cases

import { UserInterface } from "../adapters/ApiSchema";
import { HomeRoom } from "../img/Icons";

let iTry = (f: Function) => {
    try {
        return f();
    } catch (ex) {
        return ex;
    }
};
// particularly in things like the wrapper app that hides the entire console.
export function DiagPage(props: { user: UserInterface | undefined }) {

    return (<div>
        Welcome to diag!
        <ul>
            <li key="ua">UserAgent: {iTry(() => window.navigator.userAgent)}</li>
            <li key="user">User: <pre>{props.user ? JSON.stringify(props.user, null, 4) : "none"}</pre></li>
        </ul>
        <HomeRoom />
    </div>)
}